import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import {ToasterService as ToastrService} from "angular2-toaster";
import * as $ from 'jquery/dist/jquery.min.js';
import {UtilsService} from "./utils.service";
import {ToastrConfigService} from './toastr-config.service';
import { Location } from '@angular/common';

@Injectable()
export class UserService {

  // Create a stream of logged in status to communicate throughout app
  loggedIn: boolean;
  loggedIn$ = new BehaviorSubject<boolean>(this.loggedIn);
  isLoginActive: boolean = false;
  isAdmin = false;
  isEditor = false;
  id = null;
  hostname = '';
  loginModal = null;
  registrationModal = null;
  auth2:any;
  googleLogin1 = null;
  googleLogin2 = null;

  constructor(
    private router: Router,
    private http: HttpClient,
    private toastr: ToastrService,
    public utilsService: UtilsService,
    public toastrConfig: ToastrConfigService,
    private readonly location: Location) {
    this.hostname = this.utilsService.getHostname();
    if (this.isAuthenticated()) this.afterSignedIn();
    else {
      $("body").addClass("not-logged-in");
    }
    this.googleAuthSDK();
  }

  public afterSignedIn() {
    this.setLoggedIn(true);
    this.refreshProfile(null);
  }

  public getUserProfile() {
    let profile = localStorage.getItem("profile");
    if (!profile) return false;
    let profileJSON = null;
    try {
      profileJSON = JSON.parse(profile);
    } catch(e) {}
    if (!profileJSON) return false;
    return profileJSON;
  }

  public isAuthenticated() {
    // Check if there's an unexpired access token
    const helper = new JwtHelperService();
    const myRawToken = localStorage.getItem('token');

    let authenticated = !helper.isTokenExpired(myRawToken);
    if (authenticated) return true;

    return false;
  }

  public async signup(registerModel: any, loading: any) {
    let location = this.location.path();

    loading = true;
    this.http.post('/api/auth/signup', registerModel).subscribe(
      async (data:any) => {
        this._setSession(data);

        window.location.reload();
      },
      error => {
        let errorMessage = "An error occurred trying to sign you up, please check the information and try again";
        if (error.error && error.error.details && error.error.details.indexOf("A record with that `email` already exists") != -1)
          errorMessage = "This e-mail is already registered, please try to sign in or retrieve your password";
        this.toastr.pop(this.toastrConfig.getToast("error", "", errorMessage));
        loading = false;
      });
  }

  public async refreshProfile(callback) {
    try {
      let error = false;
      let result:any = null;
      try {
        result = await this.http.get('/api/user/refresh').toPromise();
      }
      catch(e) {
        error = true;
      }

      if (!result || error) {
        this.setLoggedIn(false);
        if (callback) callback();
        return false;
      }

      localStorage.setItem('profile', JSON.stringify(result));
      if (result.type === 'admin') this.isAdmin = true;
      if (result.type === 'editor') this.isEditor = true;
      this.id = result.id;
      if (callback) callback();
    }
    catch(e) {
      if (callback) callback();
    }
  }

  public async signin(loginModel: any, destination) {
    let location = this.location.path();

    this.http.post('/api/auth/signin', loginModel).subscribe(
      async (data:any) => {
        this._setSession(data);
        this.toastr.clear();

        if (destination === "admin" && data.user.type !== "user") await this.router.navigate(["/admin"]);
        else await this.router.navigate(["/"]);

        window.location.reload();
        this.afterSignedIn();
      },
      (res) => {
        let errorMessage = "Incorrect credentials, please try again."
        if (res.error === 'Forbidden') errorMessage = "You do not have access to this application. Please contact an administrator.";
        this.toastr.pop(this.toastrConfig.getToast("error", "", errorMessage));
      });
  }

  public recover(recoverModel: any) {
    this.http.post('/api/auth/recover', recoverModel).subscribe(
      () => {
        this.toastr.pop(this.toastrConfig.getToast("success", "", 'If your email is in our system you will receive further instructions on how to reset your password.'));
        this.router.navigate(["/admin"]);
      },
      () => {
        let errorMessage = "An error occurred trying to recover your password, please check the information and try again";
        this.toastr.pop(this.toastrConfig.getToast("error", "", errorMessage));
      });
  }

  public resetpass(resetpassModel: any) {
    this.http.post('/api/auth/resetpass', resetpassModel).subscribe(
      async (result:any) => {
        this.toastr.pop(this.toastrConfig.getToast("success", "", 'Your password was updated'));
        await this.signin({
          email: result.user.email,
          password: resetpassModel.password
        }, result.user.type);
      },
      () => {
        let errorMessage = "An error occurred trying to update your password, please check the information and try again";
        this.toastr.pop(this.toastrConfig.getToast("error", "", errorMessage));
      });
  }

  private _setSession(data) {
    localStorage.setItem('token', data.token);
    localStorage.setItem('profile', JSON.stringify(data.user));
    this.setLoggedIn(true);
  }

  private setLoggedIn(value: boolean) {
    // Update login status subject
    this.loggedIn$.next(value);
    this.loggedIn = value;
    if (this.loggedIn) $("body").removeClass("not-logged-in");
  }

  async logout() {
    // Remove tokens and profile and update login status subject
    localStorage.removeItem('token');
    localStorage.removeItem('profile');

    setTimeout(() => {
      this.setLoggedIn(false);
      $("body").empty();
      window.location.href = "//" + this.hostname + '/'
    }, 500)
  }

  async logoutAdmin() {
    // Remove tokens and profile and update login status subject
    localStorage.removeItem('token');
    localStorage.removeItem('profile');
    setTimeout(() => {
      this.setLoggedIn(false);
      $("body").empty();
      window.location.href = "//" + this.hostname + '/admin'
    }, 500)
  }

  toggleClass() {
    this.isLoginActive = !this.isLoginActive;
    this.updateClass();
  }

  updateClass() {
    if (this.isLoginActive) {
      setTimeout(function() {
        $('.front .form-button-container').hide();
        $('.back .form-button-container').show();
      }, 300);
    }
    else {
      setTimeout(function() {
        $('.front .form-button-container').show();
        $('.back .form-button-container').hide();
      }, 300);
    }
  }

  profile(key, value?) {
    let profile = this.getUserProfile();
    if (value === null || value === undefined) return profile[key];
    else {
      profile[key] = value;
      localStorage.setItem('profile', JSON.stringify(profile));
    }
  }

  openLogin() {
    if (this.loginModal) {
      this.loginModal.show();
      return true;
    }
  }

  closeLogin() {
    if (this.loginModal) this.loginModal.hide();
  }

  openRegistration() {
    if (this.registrationModal) {
      this.registrationModal.show();
    }
  }

  closeRegistration() {
    if (this.registrationModal) this.registrationModal.hide();
  }

  async authenticateWithGoogle() {
    if (this.googleLogin1 && this.googleLogin2) {
      this.auth2.attachClickHandler(this.googleLogin1.nativeElement, {},
        (googleAuthUser: any) => {
          this.handleGoogleUser(googleAuthUser);
        }, (error: any) => {});

      this.auth2.attachClickHandler(this.googleLogin2.nativeElement, {},
        (googleAuthUser: any) => {
          this.handleGoogleUser(googleAuthUser);
        }, (error: any) => {});
    }

    let currentUser = this.auth2.currentUser.get();
    if (currentUser) this.handleGoogleUser(currentUser);
  }

  async handleGoogleUser(googleAuthUser) {
    if (!googleAuthUser) return;

    let profile = googleAuthUser.getBasicProfile();
    if (!profile) return;

    let authResponse = googleAuthUser.getAuthResponse(true);
    let accessToken = authResponse.access_token;

    let email = profile.getEmail();
    let picture = profile.getImageUrl();
    let name = profile.getName();
    let [firstName, lastName] = name.split(" ");

    let country = '';

    try {
      let data:any = await this.http.get("https://people.googleapis.com/v1/people/me?personFields=addresses&access_token=" + accessToken + "&key=AIzaSyDNtlAr7n050RT5LMoDC49qwdXI8gPCRGs").toPromise();
      if (data && data.addresses && data.addresses[0] && data.addresses[0].formattedValue) {
        country = data.addresses[0].formattedValue.split(",").pop().trim();
      }
    }
    catch(e) {}

    try {
      let user:any = await this.http.post("/api/user/emailExists", {email: email}).toPromise();
      if (!user?.user?.id) {
        let password = this.generatePassword();
        this.signup({
          firstName: firstName,
          lastName: lastName,
          type: 'user',
          enabled: true,
          email: email,
          password: password,
          repeatPassword: password,
          justAdded: false,
          passwordSocial: password,
          picture: picture
        }, 'user');
      }
      else {
        if (user.user.passwordSocial) {
          this.signin({
            email: email,
            password: user.user.passwordSocial,
            picture: picture,
            country: country
          }, 'user');
        }
        else {
          let password = this.generatePassword();
          await this.http.post("/api/user/setPasswordSocial", {
            email: email,
            passwordSocial: password,
            picture: picture,
            country: country
          }).toPromise();
          this.signin({
            email: email,
            password: password,
            picture: picture,
            country: country
          }, 'user');
        }
      }
    }
    catch(e) {}
  }

  generatePassword = (length = 20, wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$') => {
    return Array.from(crypto.getRandomValues(new Uint32Array(length)))
      .map((x) => wishlist[x % wishlist.length])
      .join('');
  }

  googleAuthSDK() {
    (<any>window)['googleSDKLoaded'] = () => {
      (<any>window)['gapi'].load('auth2', () => {
        this.auth2 = (<any>window)['gapi'].auth2.init({
          //client_id: '466985557465-di4u6eggb32npj7fnet7fmpsn37djm52.apps.googleusercontent.com',
          plugin_name: 'login',
          cookiepolicy: 'single_host_origin',
          scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/user.addresses.read'
        });
        if (!this.isAuthenticated()) {
          this.auth2.then(() => {
            this.authenticateWithGoogle();
          });
        }
      });
    }

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement('script');
      js.id = id;
      js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
      fjs?.parentNode?.insertBefore(js, fjs);
    }(document, 'script', 'google-jssdk'));
  }
}
