<toaster-container [toasterconfig]="toasterconfig"></toaster-container>
<div *ngIf="inited">
  <ng-container *ngIf="isAdminPage">
    <div class="app-header navbar">
      <button class="navbar-toggler sidebar-toggler" type="button" data-toggle="sidebar-show" (click)="appSidebar.toggleMinimized()">
        <span class="navbar-toggler-icon"></span>
      </button>
      <a class="navbar-brand" routerLink="/">
        <img  class="navbar-brand-full" src="favicon.png" height="42" alt="Revach">
      </a>
      <ul class="nav navbar-nav">
        <li class="nav-item px-3 header-subtitle">{{page}}</li>
      </ul>
      <ul class="nav navbar-nav ml-auto mr-2">
        <li [ngClass]="{'menu-open': header.accountMenuOpen, 'has-picture': user.profile('picture'), 'has-initials': !user.profile('picture')}" class="account-dropdown"
            (click)="toggleMenu()" (clickOutside)="closeMenu()" style="margin-top: -10px;">
          <div class="account-dropdown-icon account-dropdown-icon-initials" [hidden]="user.profile('picture')">{{currentUser.firstNameLetter}}{{currentUser.lastNameLetter}}</div>
          <img class="account-dropdown-icon" src="{{user.profile('picture')}}" *ngIf="user.profile('picture')" />
          <ul>
            <li class="account-menu-item" (click)="changePassword()" style="padding-left: 22.5px;"><a href="javascript:;">Change Password</a></li>
            <li><button class="logout-button" (click)="user.logoutAdmin()">Logout</button></li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="app-body">
      <app-sidebar #appSidebar [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized" (minimizedChange)="toggleMinimize($event)" class="hide-on-print">
        <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="appSidebar.minimized"></app-sidebar-nav>
        <app-sidebar-minimizer></app-sidebar-minimizer>
      </app-sidebar>
      <main class="main">
        <div class="container-fluid bg-white p-0">
          <router-outlet></router-outlet>
        </div>
      </main>
    </div>
  </ng-container>
  <ng-container *ngIf="!isAdminPage">
    <main [@routeAnimations]="getRouteAnimationData(outlet)">
      <router-outlet #outlet="outlet"></router-outlet>
    </main>
  </ng-container>
</div>
<div class="overlay"></div>
