import {PipeTransform, Pipe} from '@angular/core';

@Pipe({name: 'timesReverse'})
export class TimesReversePipe implements PipeTransform {
  transform(value: any): any {
    value = parseInt(value);
    const iterable = {};
    iterable[Symbol.iterator] = function* () {
      let n = value;
      while (n > 0) {
        yield n--;
      }
    };
    return iterable;
  }
}
