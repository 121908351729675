import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from "@angular/common/http";
import {NgForm} from "@angular/forms";
import {UserService} from "../../../../_services/user.service";
import {ToasterService as ToastrService} from "angular2-toaster";
import {fadeBetweenAnimation} from '../../../../animations';
import {ToastrConfigService} from '../../../../_services/toastr-config.service';

@Component({
  selector: 'app-add-edit-user',
  templateUrl: './add-edit-user.component.html',
  animations: [
    fadeBetweenAnimation
  ]
})
export class AddEditUserComponent implements OnInit {
  @Input() public user;
  @Input() public emails;

  @ViewChild('userForm') private userForm: NgForm;
  @ViewChild('firstNameError') private firstNameErrorTooltip: NgbTooltip;
  @ViewChild('lastNameError') private lastNameErrorTooltip: NgbTooltip;
  @ViewChild('emailError') private emailErrorTooltip: NgbTooltip;

  errors:any = {
    'first-name': false,
    'last-name': false,
    'email': false
  }

  saving = false;

  constructor(public activeModal: NgbActiveModal,
              public http: HttpClient,
              public userService: UserService,
              public toastr: ToastrService,
              public toastrConfig: ToastrConfigService) {}

  async ngOnInit() {
    if (!this.user) {
      this.user = {
        firstName: '',
        lastName: '',
        email: '',
        type: 'admin',
        enabled: true
      }
    }
  }

  validate() {
    if (!this.userForm.form.get("first-name").valid) {
      this.errors['first-name'] = "Please type in the first name.";
      setTimeout(() => this.firstNameErrorTooltip.open(), 20);
      return false;
    }
    else if (!this.userForm.form.get("last-name").valid) {
      this.errors['last-name'] = "Please type in the last name.";
      setTimeout(() => this.lastNameErrorTooltip.open(), 20);
      return false;
    }
    else if (!this.userForm.form.get("email").valid) {
      this.errors['email'] = "Please type in a valid e-mail address.";
      setTimeout(() => this.emailErrorTooltip.open(), 20);
      return false;
    }
    else if (this.emails.includes(this.user.email)) {
      this.toastr.pop(this.toastrConfig.getToast("error", "", "A user with the same email address already exists."));
      return false;
    }

    return true;
  }

  setUserType(event) {
    this.user.type = event.target.value.toLowerCase();
  }

  async saveUser() {
    if (!this.validate() || this.saving) return;

    this.saving = true;

    try {
      await this.http.post("api/user/addupdate", this.user).toPromise();
      this.toastr.pop(this.toastrConfig.getToast("success", "", "The user has been saved"));
      this.activeModal.dismiss();
      this.saving = false;
    }
    catch(e) {
      this.toastr.pop(this.toastrConfig.getToast("error", "", "Your user could not be saved, please try again"));
      this.saving = false;
    }
  }

  clearErrors(type) {
    this.errors[type] = false;
  }
}
