<div class="modal padded-modal">
  <div class="modal-dialog modal-dialog-centered" style="min-width: 650px !important;">
    <div class="modal-content">
      <form (ngSubmit)="updatePassword()" role="form" action="" method="post" class="form-horizontal" #passwordForm="ngForm">
        <div class="modal-header">
          <div class="modal-title">Change Password</div>
          <div class="modal-close" (click)="activeModal.dismiss()"></div>
        </div>
        <div class="modal-body">
          <div class="form-group row" [ngClass]="{'input-group-invalid': errors['password']}"
          [ngbTooltip]="errors['password']" triggers="manual:manual" #passwordError="ngbTooltip" tooltipClass="error-tooltip" [autoClose]="false">
            <label class="col-md-3 col-form-label" for="password">New Password</label>
            <div class="col-md-9">
              <input type="password" id="password" name="password" class="form-control" (focus)="clearErrors('password')" [(ngModel)]="this.passwordModel.new" required>
            </div>
          </div>
          <div class="row" [ngClass]="{'input-group-invalid': errors['last-name']}"
               [ngbTooltip]="errors['confirm-password']" triggers="manual:manual" #confirmPasswordError="ngbTooltip" tooltipClass="error-tooltip" [autoClose]="false">
            <label class="col-md-3 col-form-label" for="confirm-password">Confirm Password</label>
            <div class="col-md-9">
              <input type="password" id="confirm-password" name="confirm-password" class="form-control"
                     (focus)="clearErrors('confirm-password')" [(ngModel)]="this.passwordModel.confirm" required>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal" (click)="activeModal.dismiss()">Cancel</button>
          <button type="submit" class="btn btn-sm btn-primary">Update Password</button>
        </div>
      </form>
    </div>
  </div>
</div>
