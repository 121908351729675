import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {UserService} from "./_services/user.service";
import {GuardService} from "./_services/guard.service";
import {ToasterModule} from 'angular2-toaster';

import { P404Component } from './_routes/error/404.component';
import { P500Component } from './_routes/error/500.component';

import {JwtModule} from "@auth0/angular-jwt";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";

import {ModalModule} from "ngx-bootstrap/modal";
import {AuthInterceptor} from "./_services/http-interceptor.interceptor";
import {AppAsideModule, AppBreadcrumbModule, AppSidebarModule, AppFooterModule, AppHeaderModule} from "@coreui/angular";
import {PerfectScrollbarModule} from "ngx-perfect-scrollbar";
import {ChangePasswordComponent} from "./_routes/admin/users/change-password/change-password.component";
import {NgbModalModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {DefaultPageService} from "./_services/default-page.service";
import {LoadingComponent} from "./_routes/loading/loading.component";
import {AddEditUserComponent} from "./_routes/admin/users/add-edit-user/add-edit-user.component";
import {UtilsService} from "./_services/utils.service";
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrConfigService} from './_services/toastr-config.service';
import {ClickOutsideModule} from 'ng-click-outside';

import {CommonItemsModule} from "./commonItems.module";

export function tokenGetter() {
  return localStorage.getItem('token');
}

@NgModule({
  declarations: [
    AppComponent,
    P404Component,
    P500Component,
    ChangePasswordComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    BsDatepickerModule.forRoot(),
    ToasterModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    }),
    ModalModule,
    PerfectScrollbarModule,
    NgbTooltipModule,
    BsDropdownModule.forRoot(),
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    NgbModalModule,
    ClickOutsideModule,
    CommonItemsModule
  ],
  providers: [
    UserService,
    GuardService,
    DefaultPageService,
    UtilsService,
    ToastrConfigService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  entryComponents: [
    ChangePasswordComponent,
    LoadingComponent,
    AddEditUserComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
