import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {fadeBetweenAnimation} from '../../animations';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  animations: [
    fadeBetweenAnimation
  ]
})
export class LoadingComponent implements OnInit {
  @Input() public message:any;

  constructor() {}

  ngOnInit() {
  }
}
