import {Injectable} from '@angular/core';
import {Toast} from 'angular2-toaster';

@Injectable({
  providedIn: 'root',
})
export class ToastrConfigService {
  constructor() {}
  getToast(type, nothing, message) {
    let toast: Toast = {
      type: type,
      title: '',
      body: message,
      showCloseButton: true
    };

    return toast;
  }
}
