import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {ToasterService as ToastrService} from 'angular2-toaster';
import {DOCUMENT} from '@angular/common';
import {UserService} from './user.service';
import * as localConfig from '../../../config/local-fe.js';
import {v4 as uuidv4} from 'uuid';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class SocketsService {
  changeDetector = null;
  data = {
    finished: [],
    unfinished: [],
    queueStopped: false,
    currentProgress: 0
  };

  loadingQueue = true;

  public visitorID = null;

  public progressEventReceived$ = new Subject<any>();

  constructor(@Inject(DOCUMENT) private document: HTMLDocument,
              public http: HttpClient,
              public modalService: NgbModal,
              public router: Router,
              public toastr: ToastrService,
              public user: UserService) {
    this.visitorID = uuidv4();
    this.setupSubscriptions();
  }

  connectSocketIfNotConnected() {
    window['io'].sails.url = localConfig.apiServer;
    window['io'].sails.reconnection = true;

    try {
      if (!window['io'].socket || window['io'].socket.url !== window['io'].sails.url) {
        if (window['io'].socket) window['io'].socket.disconnect();
        window['io'].socket = window['io'].sails.connect(window['io'].sails.url);
      }
    }
    catch(e) {
      console.log(e);
    }
  }

  async setupSubscriptions() {
    this.connectSocketIfNotConnected();

    window['io'].socket.on('progress-update', (msg:any) => {
      if (msg.visitorID !== this.visitorID) return;

      this.progressEventReceived$.next(msg);
    });
  }

  detectChanges() {
    if (this.changeDetector) this.changeDetector.detectChanges();
  }
}
