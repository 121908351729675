<div class="modal padded-modal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">{{user.id ? 'Edit' : 'Add'}} User</div>
        <div class="modal-close" (click)="activeModal.dismiss()"></div>
      </div>
      <div class="modal-body">
        <form action="" method="post" class="form-horizontal" #userForm="ngForm">
          <div class="form-group-header mt-0">User Information</div>
          <div class="form-group row" [ngClass]="{'input-group-invalid': errors['first-name']}"
          [ngbTooltip]="errors['first-name']" triggers="manual:manual" #firstNameError="ngbTooltip" tooltipClass="error-tooltip" [autoClose]="false">
            <label class="col-md-3 col-form-label" for="first-name">First name</label>
            <div class="col-md-9">
              <input type="text" id="first-name" name="first-name" class="form-control" placeholder="Enter the first name.."
                     (focus)="clearErrors('first-name')" [(ngModel)]="user.firstName" required>
            </div>
          </div>
          <div class="form-group row" [ngClass]="{'input-group-invalid': errors['last-name']}"
               [ngbTooltip]="errors['last-name']" triggers="manual:manual" #lastNameError="ngbTooltip" tooltipClass="error-tooltip" [autoClose]="false">
            <label class="col-md-3 col-form-label" for="email">Last name</label>
            <div class="col-md-9">
              <input type="text" id="last-name" name="last-name" class="form-control" placeholder="Enter the last name.."
                     (focus)="clearErrors('last-name')" [(ngModel)]="user.lastName" required>
            </div>
          </div>
          <div class="form-group row" [ngClass]="{'input-group-invalid': errors['email']}"
               [ngbTooltip]="errors['email']" triggers="manual:manual" #emailError="ngbTooltip" tooltipClass="error-tooltip" [autoClose]="false">
            <label class="col-md-3 col-form-label" for="email">Email</label>
            <div class="col-md-9">
              <input type="email" id="email" name="email" email="true" class="form-control" placeholder="Enter the email address.."
                     (focus)="clearErrors('email')" [(ngModel)]="user.email" required>
            </div>
          </div>
          <ng-container *ngIf="userService.id !== user.id">
            <div class="form-group-header">Credentials</div>
            <div class="form-group row">
              <label class="col-md-3 col-form-label" for="user-type">User Type</label>
              <div class="col-md-9">
                <select class="form-control" id="user-type" (change)="setUserType($event)">
                  <option [selected]="user.type === 'admin'" [value]="'admin'">Administrator</option>
                  <option [selected]="user.type === 'editor'" [value]="'editor'">Editor</option>
                  <option [selected]="user.type === 'user'" [value]="'user'">Website User</option>
                </select>
              </div>
            </div>
          </ng-container>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal" (click)="activeModal.dismiss()">Cancel</button>
        <button type="button" class="btn btn-sm btn-primary" (click)="saveUser()">Save User</button>
      </div>
    </div>
  </div>
</div>
