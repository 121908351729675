import { NgModule } from '@angular/core';
import {CapitalizeFirstPipe} from "./_pipes/capitalizefirst.pipe";
import {BooleanToStringPipe} from "./_pipes/booleantostring.pipe";
import {TimesPipe} from "./_pipes/times.pipe";
import {HoverClassDirective} from "./_directives/hoverclass.directive";
import {LoadingComponent} from "./_routes/loading/loading.component";
import {SafeHTMLPipe} from "./_pipes/safehtml.pipe";
import {ClickOutsideModule} from "ng-click-outside";
import {FormsModule} from "@angular/forms";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {TimesReversePipe} from "./_pipes/times-reverse.pipe";
import { BsModalService } from 'ngx-bootstrap/modal';
import { D3Service } from 'd3-ng2-service';
import {PreloadImageDirective} from "./_directives/preloadimage.directive";
import {UtilsService} from "./_services/utils.service";
import {SafeStylePipe} from './_pipes/safestyle.pipe';
import {FieldsSelectorComponent} from './_elements/fields-selector-list/fields-selector.component';
import {SwipeDirective} from './_directives/swipe.directive';
import {CommonModule} from '@angular/common';
import {ToastrConfigService} from './_services/toastr-config.service';
import {DndDirective} from './_directives/dnd.directive';
import {ComboBoxComponent} from "./_elements/combobox/combo-box.component";
import {CapitalizeFirstOfAllPipe} from "./_pipes/capitalizefirstofall.pipe";

@NgModule({
  imports: [
    CommonModule,
    ClickOutsideModule,
    FormsModule,
    DragDropModule
  ],
  declarations: [
    CapitalizeFirstPipe,
    CapitalizeFirstOfAllPipe,
    BooleanToStringPipe,
    SafeHTMLPipe,
    SafeStylePipe,
    TimesPipe,
    TimesReversePipe,
    HoverClassDirective,
    PreloadImageDirective,
    DndDirective,
    SwipeDirective,
    LoadingComponent,
    FieldsSelectorComponent,
    ComboBoxComponent
  ],
  exports: [
    CapitalizeFirstPipe,
    CapitalizeFirstOfAllPipe,
    BooleanToStringPipe,
    TimesPipe,
    TimesReversePipe,
    SafeHTMLPipe,
    SafeStylePipe,
    HoverClassDirective,
    LoadingComponent,
    PreloadImageDirective,
    DndDirective,
    SwipeDirective,
    FieldsSelectorComponent,
    ComboBoxComponent
  ],
  providers: [
    CapitalizeFirstPipe,
    CapitalizeFirstOfAllPipe,
    BooleanToStringPipe,
    TimesPipe,
    TimesReversePipe,
    BsModalService,
    D3Service,
    UtilsService,
    ToastrConfigService
  ],
  entryComponents: [
    LoadingComponent
  ]
})
export class CommonItemsModule {}
