import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeFirstOfAll'
})
export class CapitalizeFirstOfAllPipe implements PipeTransform {
  transform(value: string): string {
    if (value === null) return 'Not assigned';
    if (value && value.length && value.length > 0) {
      let delimiters = [' ', '-', '.'];
      delimiters.forEach((delimiter) => {
        let parts = value.split(delimiter);
        parts = parts.map((part) => {
          return part.charAt(0).toUpperCase() + part.slice(1);
        });
        value = parts.join(delimiter);
      });
      return value;
    }
    else {
      return value;
    }
  }
}
