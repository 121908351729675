import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from './user.service';

@Injectable()
export class DefaultPageService implements CanActivate {

  constructor(private userService: UserService, private router: Router) {}

  canActivate() {
    this.router.navigate(['admin', 'users']);
    return true;
  }
}
