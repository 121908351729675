import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";
import {fadeBetweenAnimation} from '../../animations';

@Component({
  templateUrl: '500.component.html',
  animations: [
    fadeBetweenAnimation
  ]
})
export class P500Component implements OnInit {

  constructor(private title: Title) {}

  ngOnInit() {
    this.title.setTitle("Internal Server Error");
  }

}
