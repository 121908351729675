import { NgModule } from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {GuardService} from "./_services/guard.service";
import {P404Component} from "./_routes/error/404.component";
import {P500Component} from "./_routes/error/500.component";

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import("./_routes/website/website.module").then(m => m.WebsiteModule)
  },
  {
    path: 'admin',
    loadChildren: () => import("./_routes/admin/admin.module").then(m => m.AdminModule),
    canActivate: [GuardService]
  },
  {
    path: 'login',
    loadChildren: () => import("./_routes/public/public.module").then(m => m.PublicModule)
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: '**',
    redirectTo: '404'
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
