import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    title: true,
    name: 'Panel'
  },
  {
    name: 'Users',
    url: '/admin/users',
    icon: 'cil-group'
  }
];
