import {Component, HostListener, OnInit} from '@angular/core';
import {Router, NavigationEnd, ChildrenOutletContexts} from '@angular/router';
import {UserService} from "./_services/user.service";
import {Title} from "@angular/platform-browser";
import { navItems } from './nav';
import {ToasterConfig} from "angular2-toaster";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ChangePasswordComponent} from "./_routes/admin/users/change-password/change-password.component";
import * as $ from 'jquery/dist/jquery.min.js';
import {fadeBetweenAnimation} from './animations';
import {SocketsService} from "./_services/sockets.service";

@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    fadeBetweenAnimation
  ]
})
export class AppComponent implements OnInit {
  app = null;

  public sidebarMinimized = false;
  public navItems = navItems;

  public toasterconfig: ToasterConfig =
    new ToasterConfig({
      timeout: 5000,
      positionClass: 'toast-top-center',
      preventDuplicates: true
    });

  page = null;

  adminOnlyPages = ["Users"];

  titles = {
    'admin/settings': 'Settings',
    'admin/users': 'Users'
  };

  isAdminPage = false;
  isWebsitePage = false;
  isMessagesPage = false;

  inited = false;

  websitePages = ["about-us", "policy", "terms", "upload", "how-it-works"];

  header = {
    accountMenuOpen: false,
    accountMenuOpenMobile: false
  };
  currentUser:any = {};

  constructor(private router: Router,
              public user: UserService,
              private title: Title,
              public modalService: NgbModal,
              public socketsService: SocketsService,
              private contexts: ChildrenOutletContexts) {}

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.title.setTitle("Revach");
      this.page = null;

      this.processURLsAndPermissions();

      if (this.isWebsitePage) $('body').addClass("website-body");
      else $('body').removeClass("website-body");

      if (this.isMessagesPage) $('body').addClass("messages-body");
      else $('body').removeClass("messages-body");

      window.scrollTo(0, 0);

      this.inited = true;

      if (this.user.profile("justAdded", null) === true) {
        this.changePassword();
      }
    });
  }

  processURLsAndPermissions() {
    let url = this.router.url;
    if (url && url[0] === "/") url = url.replace("/", "");

    Object.keys(this.titles).forEach(title => {
      if (url.startsWith(title)) {
        this.title.setTitle("Revach: " + this.titles[title]);
        this.page = this.titles[title];
      }
    });

    this.isAdminPage = url.startsWith("admin");

    if (url === "" || this.websitePages.some(page => url.startsWith(page))) this.isWebsitePage = true;

    this.isMessagesPage = url.startsWith("messages");

    this.user.refreshProfile(() => {
      if (!this.user.isAdmin) {
        this.navItems = this.navItems.filter(item => this.adminOnlyPages.indexOf(item.name) === -1);
      }

      this.currentUser.firstNameLetter = this.user.profile("firstName") ? this.user.profile("firstName").substring(0, 1) : '';
      this.currentUser.lastNameLetter = this.user.profile("lastName") ? this.user.profile("lastName").substring(0, 1) : '';
      this.currentUser.name = this.user.profile("firstName") + " " + this.user.profile("lastName");
      this.currentUser.email = this.user.profile("email");
      this.currentUser.type = this.user.profile("type");

      let isAuthenticated = this.user.isAuthenticated();
      let type = this.user.profile("type");
      if (this.isAdminPage && (!isAuthenticated || (type !== "admin" && type !== "editor"))) {
        this.router.navigate(['/login']);
      }
      if (url.startsWith("admin/users") && type !== "admin") {
        this.router.navigate(['/login']);
      }
      if (this.isMessagesPage && !isAuthenticated) {
        this.router.navigate(['/'], { queryParams: {a: 'login'}});
      }
      if (url.startsWith("login") && isAuthenticated && (type === "admin" || type === "editor")) {
        this.router.navigate(['/admin']);
      }
    });
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  async changePassword() {
    const modalRef = this.modalService.open(ChangePasswordComponent, {
      backdropClass: 'light-grey-modal',
      windowClass: 'list-modal',
      centered: true
    });

    try { await modalRef.result; } catch(e) {}

    this.user.refreshProfile(null);
  }

  getRouteAnimationData(outlet) {
    return outlet.activatedRouteData.animation;
  }

  closeMenu() {
    this.header.accountMenuOpen = false;
  }

  toggleMenu() {
    this.header.accountMenuOpen = !this.header.accountMenuOpen;
  }

  @HostListener('document:visibilitychange')
  appVisibility() {
    if (!document.hidden) {
      this.processURLsAndPermissions();
    }
  }
}
