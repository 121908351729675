import { Injectable } from '@angular/core';

@Injectable()
export class UtilsService {

  constructor() {}

  public getHostname() {
    let hostname = window.location.hostname;
    let port = window.location.port;
    return hostname + (port !== "80" ? (":" + port) : "");
  }
}
