import {Component, OnInit} from '@angular/core';
import {Title} from "@angular/platform-browser";
import {fadeBetweenAnimation} from '../../animations';

@Component({
  templateUrl: '404.component.html',
  animations: [
    fadeBetweenAnimation
  ]
})
export class P404Component implements OnInit {

  constructor(private title: Title) {}

  ngOnInit() {
    this.title.setTitle("Page Not Found");
  }
}
