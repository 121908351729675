import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from "@angular/common/http";
import {NgForm} from "@angular/forms";
import {ToasterService as ToastrService} from "angular2-toaster";
import {fadeBetweenAnimation} from '../../../../animations';
import {ToastrConfigService} from '../../../../_services/toastr-config.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  animations: [
    fadeBetweenAnimation
  ]
})
export class ChangePasswordComponent implements OnInit {
  @ViewChild('passwordForm') private passwordForm: NgForm;
  @ViewChild('passwordError') private passwordErrorTooltip: NgbTooltip;
  @ViewChild('confirmPasswordError') private confirmPasswordErrorTooltip: NgbTooltip;

  errors:any = {
    'password': false,
    'confirm-password': false
  }

  passwordModel = {
    new: '',
    confirm: ''
  }

  constructor(public activeModal: NgbActiveModal,
              public http: HttpClient,
              public toastr: ToastrService,
              public toastrConfig: ToastrConfigService) {}

  async ngOnInit() {}

  validate() {
    if (!this.passwordForm.form.get("password").valid) {
      this.errors['password'] = "Please type in a password.";
      setTimeout(() => this.passwordErrorTooltip.open(), 20);
      return false;
    }
    else if (!this.passwordModel.new ||
      this.passwordModel.new.length < 8 ||
      !/\d/.test(this.passwordModel.new) ||
      !/[A-Z]/.test(this.passwordModel.new) ||
      !/[a-z]/.test(this.passwordModel.new)
    ) {
      this.errors['password'] = "Please ensure your password meets these minimum requirements: at least 8 characters, one uppercase and lowercase character, and at least one number.";
      setTimeout(() => this.passwordErrorTooltip.open(), 20);
      return false;
    }
    else if (!this.passwordForm.form.get("confirm-password").valid) {
      this.errors['confirm-password'] = "Please confirm your password.";
      setTimeout(() => this.confirmPasswordErrorTooltip.open(), 20);
      return false;
    }
    else if (this.passwordModel.new !== this.passwordModel.confirm) {
      this.errors['confirm-password'] = "Please make sure the passwords match.";
      setTimeout(() => this.confirmPasswordErrorTooltip.open(), 20);
      return false;
    }

    return true;
  }

  async updatePassword() {
    if (!this.validate()) return;

    try {
      await this.http.post("api/user/updatePassword", this.passwordModel).toPromise();
      this.toastr.pop(this.toastrConfig.getToast("success", "", "Your password has been updated"));
      this.activeModal.dismiss();
    }
    catch(e) {
      this.toastr.pop(this.toastrConfig.getToast("error", "", "Your password could not be updated, please try again"));
    }
  }

  clearErrors(type) {
    this.errors[type] = false;
  }
}
