import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'booleanToString'
})
export class BooleanToStringPipe implements PipeTransform {
  transform(value:any): string {
    if (value === true) return "Yes";
    if (value === false) return "No";
    return value;
  }
}
